export default {
  name: 'Header',
  data: function () {
    return {
      isMenuActive: false
    }
  },
  methods: {
    toggleMenu: function () {
      this.isMenuActive = !this.isMenuActive
    }
  }
}

import Header from '../../shared/header/Header.vue'
import Footer from '../../shared/footer/Footer.vue'
import axios from 'axios'

export default {
  name: 'JoinUs',
  data () {
    return {
      showSuccessMessage: false,
      form: {
        email: '',
        company: '',
        referral: '',
        lookingFor: '',
        terms: false
      },
      errors: {
        email: '',
        company: '',
        referral: '',
        lookingFor: '',
        terms: ''
      }
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    setLookingFor (option) {
      this.form.lookingFor = option
    },
    submit (event) {
      event.preventDefault()

      axios
        .post(
          process.env.VUE_APP_API_URL + '/api/join-us',
          this.form
        ).then(response => {
          if (this.form.lookingFor === 'Meeting Girls') {
            window.location.replace('https://go.moartraffic.com/go.php?t=21359&aid=122588&sid=ContactForm')
          }

          this.form = {
            email: '',
            company: '',
            referral: '',
            lookingFor: '',
            terms: false
          }
          this.errors = {
            email: '',
            company: '',
            referral: '',
            lookingFor: '',
            terms: ''
          }
          this.showSuccessMessage = true
        })
        .catch(error => {
          this.errors = {
            email: '',
            company: '',
            referral: '',
            terms: '',
            lookingFor: ''
          }

          this.showSuccessMessage = false

          Object.keys(error.response.data.errors).forEach(key => {
            this.errors[key] = error.response.data.errors[key] ? error.response.data.errors[key][0] : ''
          })
        })
    }
  }
}

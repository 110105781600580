import { render, staticRenderFns } from "./JoinUs.vue?vue&type=template&id=d97333f0&scoped=true"
import script from "./JoinUs.js?vue&type=script&lang=js&external"
export * from "./JoinUs.js?vue&type=script&lang=js&external"
import style0 from "./JoinUs.scss?vue&type=style&index=0&id=d97333f0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d97333f0",
  null
  
)

export default component.exports